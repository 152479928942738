<template>
  <div class="page-container">
    <div class="banner-content">
      <div class="banner">
        <img :src="bannerUrl" alt>
      </div>
      <div class="page-title-content">
        <div class="page-title">
          <div class="first-title">应用领域一站式解决方案</div>
          <div class="two-title">使用范围广泛</div>
        </div>
      </div>
    </div>
    <div class="page-content-div">
      <div class="page-content">
        <div class="solution-content">
          <div class="top-content">
            <div class="back-content" @click="backTo">返回</div>
            <div class="lingyu-select">
              <span>应用领域</span>
              <!-- <i class="el-icon-arrow-right"></i> -->
              <span style="color:rgba(57, 63, 227, 1)">{{activeType?typeListObj[activeType * 1]:'化工'}}</span>
            </div>
          </div>
          <h2 class="title-h2">{{fangAnInfo.title}}</h2>
          <div class="fangan-content">
            <div class="fangan-left">
              <div class="product-img-content">
                <img class="video-img" :src="bigImg" alt>
                 <div class="modal-content"
                      v-if="fangAnInfo.videoUrl"
                      @click="changeVideoShow(true, fangAnInfo.videoUrl)">
                  <img src="../../../assets/svg/guanwang_icon_bofang.svg" alt="播放按钮">
                </div>
              </div>
              <div class="video-item-container">
                <div class="video-item-content">
                  <div v-for="(item, index) in imgArray" :key="index" class="video-item"
                       @click="changBingImg(item)">
                    <img :src="item" alt>
                  </div>
                </div>
              </div>
              <div class="fangan-text" v-html="fangAnInfo.detail">
              </div>
            </div>
            <div class="fangan-right">
              <div class="right-img">
                <!--<img src="../../../assets/dingzhi/liangguangmochoubiandai.png" alt>-->
                <img :src="imgArray[0]" alt>
              </div>
              <h3 class="right-title">{{dataInfo.prodName}}</h3>
              <div class="right-text" v-html="fangAnInfo.shortContent">
                <!-- <div class="all-text">
                  全文
                  <i class="el-icon-arrow-down"></i>
                </div>-->
              </div>
              <router-link :to="{path: '/customized', query: {packageType: packageType ? packageType : 1, areaType: activeType?typeListObj[activeType*1]:'化工'}}">
                <div class="dingzhi">
                  <span>立即定制</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </router-link>
               <div class="option-btn">
                <div class="btn-item" @click="downloadFile(1)" v-if="fangAnInfo.fileUrl">
                  方案下载
                  <img src="../../../assets/svg/guanwang_icon_xiazai.svg" alt>
                </div>
                <div class="btn-item" @click="downloadFile(2)" v-if="fangAnInfo.videoUrl">
                  视频下载
                  <img src="../../../assets/svg/guanwang_icon_xiazai.svg" alt>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video_box" v-if="isShow">
      <div class="video-div">
        <i class="el-icon-circle-close" @click="closeVideoShow()"></i>
        <video
          :src="vedioData"
          controls="controls"
          width="760px"
          height="580px"
          x5-video-player-type="h5-page"
          playsinline="true"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      typeList: [
        { name: "化工", value: 1 },
        { name: "塑料", value: 2 },
        { name: "建材", value: 3 },
        { name: "农肥", value: 4 },
        { name: "饲料", value: 5 },
        { name: "食品", value: 6 },
        { name: "其他用途", value: 7 }
      ],
        typeListObj: {
            1: "化工",
            2: "塑料",
            3: "建材",
            4: "农肥",
            5: "饲料",
            6: "食品",
            7: "其他用途"
        },
      activeType: 1,
      packageType: 1,
      isShow: false,
        vedioData: null,
        bigImg: "",
        bannerUrl: '',
        prodId: 0,
        dataInfo: {},
        fangAnInfo: {},
        fangAnIndex: 0, // 显示方案的下标
        imgArray: []
    };
  },

  mounted() {
      this.prodId = this.$route.query.prodId;
      this.fangAnIndex = this.$route.query.fangAnIndex;
      const banners = JSON.parse(localStorage.getItem('banners'));
      banners ? this.bannerUrl = banners[4]['imgUrl'] : this.bannerUrl = this.$url.backImg[4];

      this.activeType = this.$route.query.activeType;
      this.packageType = this.$route.query.packageType;
      this.getDetail();
  },
  methods: {
      downloadFile(type) {
          let urlInfo = {
              url: '',
              name: '',
          };
          // 方案
          if (type == 1) {
              urlInfo.url = this.fangAnInfo.fileUrl;
              urlInfo.name = this.fangAnInfo.fileName;
          }
          // 视频
          if (type == 2) {
              urlInfo.url = this.fangAnInfo.videoUrl;
              urlInfo.name = this.fangAnInfo.videoName;
          }
          // http://localhost:8088/file/download?fileUrl=https://admin.cndanyepack.com/group1/M00/00/00/i5-53l_vIfKAPPGVAAC32nwcV30568.png&fileName=test.png
          window.open(`http://139.159.185.222:8088/file/download?fileUrl=${encodeURI(urlInfo.url)}&fileName=${encodeURI(urlInfo.name)}`);
      },
      getDetail() {
          let url = this.$url.getBackendApiUrl(`/api/prod/get`);
          url = this.$url.addQueryString(url, 'prodId', this.prodId);
          this.$http.get(url).then(res => {
              if (res.data.code == 200) {
                  this.fangAnInfo = res.data.data.prodSolutions[this.fangAnIndex];
                  this.fangAnInfo.shortContent = this.getBr(this.fangAnInfo.shortContent);
                  this.fangAnInfo.detail = this.getBr(this.fangAnInfo.detail);

                  this.dataInfo = res.data.data;
                  this.dataInfo.prodFunction = this.dataInfo.prodFunction * 1;
                  this.imgArray = this.showJieJueImg(this.fangAnInfo.imgUrl)
              }
          })
      },
      // textarea填入的换行以及空格，页面显示转换方法
      getBr(data) {
          if (data) {
              return data.replace(/\n/g,'<br/>');
          }
      },
    backTo() {
      this.$router.go(-1);
    },

    changeVideoShow(item, data) {
      this.isShow = item;
        console.log(data);
        this.vedioData = data;
    },

    closeVideoShow() {
      this.isShow = false;
    },
    changBingImg(item) {      
      this.bigImg = item;
      console.log(this.bigImg);
    },
      showJieJueImg(imgUrl) {
          let imgArry = [];
          if (imgUrl) {
              imgArry = imgUrl.split(',');
              this.bigImg = imgArry[0];
          }
          return imgArry;
      }
  }
};
</script>

<style lang="scss" scoped>
.video_box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  .video-div {
    position: relative;
    i {
      position: absolute;
      top: -30px;
      right: -30px;
      z-index: 999;
      font-size: 25px;
      color: #fff;
      cursor: pointer;
    }
    video {
      outline: none;
    }
  }
}
.page-container {
  font-family: PingFangSC-Regular;
}
.page-content-div {
  display: flex;
  justify-content: center;
  width: 100%;
  .page-content {
    width: 1200px;
    .solution-content {
      .top-content {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .back-content {
          cursor: pointer;
          border: 1px solid rgba(228, 228, 235, 1);
          height: 40px;
          width: 139px;
          line-height: 40px;
          text-align: center;
          &:hover {
            border-color: rgba(57, 63, 227, 1);
            color: rgba(57, 63, 227, 100);
          }
        }
        .lingyu-select {
          height: 44px;
          border: 1px solid rgba(228, 228, 235, 1);
          width: 220px;
          padding: 0 10px 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // &:hover {
          //   border-color: rgba(57, 63, 227, 1);
          //   color: rgba(57, 63, 227, 100);
          // }
        }
      }
      .title-h2 {
        font-size: 26px;
        font-family: PingFangSC-Medium;
        margin: 20px 0;
      }
      .fangan-content {
        display: flex;
        justify-content: space-between;
        .fangan-left {
          flex: 1;
          .product-img-content {
            width: 100%;
            height: 440px;
            border: 1px solid rgba(233, 233, 238, 1);
            position: relative;
            // cursor: pointer;
            text-align: center;

            img.video-img {
              height: 80%;
              width: auto;
              /*object-fit: cover;*/
              /*object-position: center;*/
              margin-top: 5%;
            }
            .modal-content {
              position: absolute;
              opacity: 0.41;
              background-color: rgba(11, 16, 50, 1);
              /*width: 100%;*/
              /*height: 100%;*/
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                opacity: 0.6;
              }
            }
          }
          .video-item-container {
            width: 780px;
            overflow-x: scroll;
            /*margin-bottom: 24px;*/
            .video-item-content {
              margin-top: 20px;
              display: flex;
              flex-direction: row;
              overflow: auto;
              .video-item {
                flex: none;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                width: 220px;
                height: 130px;
                margin-right: 16px;
                border: 1px solid rgba(233, 233, 238, 1);
                img {
                  height: 80%;
                  margin-top: 5%;
                }
              }
              .video-item:last-child {
                margin-right: 0;
              }
            }
          }
          .fangan-text {
            margin-top: 24px;
            color: rgba(114, 114, 122, 100);
            font-size: 16px;
            line-height: 30px;
            font-family: PingFangSC-Regular;
          }
        }
        .fangan-right {
          width: 400px;
          margin-left: 20px;
          .right-img {
            text-align: center;
            border: 1px solid rgba(233, 233, 238, 1);
            width: 100%;
            height: 200px;

            img {
              height: 80%;
              margin-top: 5%;
            }
          }
          .right-title {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 24px;
            font-family: PingFangSC-Semibold;
          }
          .right-text {
            line-height: 30px;
            color: rgba(114, 114, 122, 100);
            font-size: 16px;
            font-family: PingFangSC-Regular;
            position: relative;
            .all-text {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 20px;
              background: #fff;
              text-align: center;
              line-height: 30px;
              width: 60px;
              color: rgba(5, 6, 16, 100);
              font-size: 16px;
              font-family: PingFangSC-Regular;
              cursor: pointer;
            }
          }
          .dingzhi {
            margin-top: 20px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(233, 233, 238, 1);
            height: 50px;
            color: #fff;
            font-size: 20px;
            background: #393fe3;
            padding-left: 30px;
            padding-right: 10px;
            position: relative;
            margin-right: 18px;
            &::before {
              position: absolute;
              content: "";
              right: -18px;
              top: 0;
              width: 0;
              height: 0;
              border: 22px solid;
              border-width: 25px 0 25px 18px;
              border-color: transparent transparent transparent #393fe3;
            }
          }
          .option-btn {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            .btn-item {
              display: flex;
              align-items: center;
              color: rgba(57, 63, 227, 100);
              font-size: 16px;
              font-family: PingFangSC-Semibold;
              cursor: pointer;
              img {
                margin-left: 5px;
                width: 15px;
              }
            }
          }
        }
      }
    }
  }
}
</style>